import { CameraIcon, RectangleGroupIcon, SwatchIcon } from '@heroicons/react/24/solid'
import { AnchorText, HeadingText, HugeText, ParagraphText } from 'components'
import React, { ReactNode, useCallback, useState } from 'react'

import { trackAnalyticsEvent } from '@/analytics/api/mixpanel'

import { HighlightText } from '../../highlightText/HighlightText'

const FEATURES: Array<{
  icon: ReactNode
  title: string
  description: ReactNode
}> = [
  {
    icon: <SwatchIcon />,
    title: 'Theming in One Line of Code',
    description: (
      <>
        Easily customize your app's look with one line of code, using{' '}
        <AnchorText href="https://daisyui.com/">DaisyUI</AnchorText>&apos;s extensive theme options or easily add your
        own custom theme. Custom fonts in a snap 🎨
      </>
    ),
  },
  {
    icon: <CameraIcon />,
    title: 'Full-Stack Asset Generation',
    description: (
      <>
        Generate icons, pngs, favicons, and OG images in one go. Keep your branding consistent and polished with a
        single command 💅
      </>
    ),
  },
  {
    icon: <RectangleGroupIcon />,
    title: '30+ Pre-built Components',
    description: (
      <>
        Need a button? How about 30+ ready-to-use components, including a{' '}
        <AnchorText href="https://storybook.js.org/">Storybook</AnchorText>, all ready to integrate into your project
        📚✨
      </>
    ),
  },
]

// Taken from packages/website/tailwind.config.ts
const THEMES = [
  'sub',
  'light',
  'dark',
  'cupcake',
  'bumblebee',
  'emerald',
  'corporate',
  'synthwave',
  'retro',
  'cyberpunk',
  'valentine',
  'halloween',
  'garden',
  'forest',
  'aqua',
  // 'lofi', // removed low contrast themes
  'pastel',
  'fantasy',
  'wireframe',
  // 'black', // as above
  // 'luxury',// as above
  'dracula',
  'cmyk',
  'autumn',
  'business',
  'acid',
  'lemonade',
  'night',
  'coffee',
  'winter',
  'dim',
  'nord',
  'sunset',
]

export const ThemingSection = () => {
  const [theme, setTheme] = useState(THEMES[0])

  const onCycleTheme = useCallback(() => {
    // select the next theme
    const currentIndex = THEMES.indexOf(theme)
    const nextIndex = (currentIndex + 1) % THEMES.length
    const nextTheme = THEMES[nextIndex]

    setTheme(nextTheme)

    trackAnalyticsEvent({
      event: 'theme_changed',
      data: { theme: nextTheme },
    })
  }, [theme])

  return (
    <>
      <HugeText className="text-center">
        Fully Camouflaged in <HighlightText>Seconds!</HighlightText>
      </HugeText>

      <ParagraphText className="max-w-2xl text-center">
        Customise your theme, generate assets, and use our components in seconds.
        <br />
        With <AnchorText href="https://tailwindcss.com/">TailwindCSS</AnchorText> and{' '}
        <AnchorText href="https://daisyui.com/">DaisyUI</AnchorText> under the hood, you can blend in or stand out{' '}
        <b>across your entire stack</b>, effortlessly 🎨
      </ParagraphText>

      <div data-theme={theme} className="mockup-code w-full max-w-lg">
        <pre
          data-prefix="$"
          className="cursor-pointer select-none text-accent transition-opacity hover:opacity-60"
          onClick={onCycleTheme}
        >
          <code>./cycle-camouflage.sh</code>
        </pre>

        <pre data-prefix=">" className="text-secondary">
          <code>⚠️ Activated via click!</code>
        </pre>

        <pre data-prefix=">">
          <code>[{theme}] active</code>
        </pre>
      </div>

      <ul className="mt-12 grid grid-cols-1 gap-x-8 gap-y-12 lg:grid-cols-3">
        {FEATURES.map(feature => (
          <li key={feature.title} className="flex flex-col gap-y-8">
            <div className="flex flex-col gap-y-4">
              <div className="flex items-center gap-x-2">
                <span className="h-6 w-6 text-secondary">{feature.icon}</span>

                <HeadingText>{feature.title}</HeadingText>
              </div>

              <ParagraphText>{feature.description}</ParagraphText>
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}

import { GlobeAltIcon, RocketLaunchIcon, ServerIcon } from '@heroicons/react/24/solid'
import { AnchorText, HeadingText, HugeText, ParagraphText, SmallText } from 'components'
import React, { ReactNode } from 'react'

import { HighlightText } from '../../highlightText/HighlightText'

const FEATURES: Array<{
  icon: ReactNode
  title: string
  description: ReactNode
}> = [
  {
    icon: <RocketLaunchIcon />,
    title: 'One Command Setup',
    description: (
      <>
        Run one <b>sweet-ass</b> script and you&apos;re ready to start coding. Everything from{' '}
        <AnchorText href="https://firebase.google.com/">Firebase</AnchorText> projects to environments is set up for you{' '}
        <b>automagically 🎉</b>
      </>
    ),
  },
  {
    icon: <GlobeAltIcon />,
    title: 'Multiple Environments',
    description: (
      <>
        <b>Why do it twice?</b> We&apos;ve got your staging and production environments covered in one go. Hosting for
        your app, website and <AnchorText href="https://storybook.js.org/">Storybook*</AnchorText>—<b>boom, done! 🌍</b>
      </>
    ),
  },
  {
    icon: <ServerIcon />,
    title: 'Ready-to-Go Firebase Backend',
    description: (
      <>
        Get a{' '}
        <b>
          powerful <AnchorText href="https://firebase.google.com/">Firebase</AnchorText> backend
        </b>{' '}
        with authentication, database, functions, and storage, <b>pre-configured and ready to roll 🔥</b>
      </>
    ),
  },
]

export const GetStartedSection = () => {
  return (
    <>
      <HugeText className="text-center">
        Get Started Flipping <HighlightText>Fast!</HighlightText>
      </HugeText>

      <ParagraphText className="max-w-2xl text-center">
        From clone to full-stack development in minutes.
        <br />
        Automatically sets up <AnchorText href="https://firebase.google.com/">Firebase</AnchorText> projects, hosting,
        and environments,
        <br />
        so you&apos;re ready to <b>start coding right away</b> 🚀
      </ParagraphText>

      <div className="mockup-code w-full max-w-lg">
        <pre data-prefix=">">
          <code>git clone $SUB</code>
        </pre>
        <pre data-prefix="$" className="text-accent">
          <code>./setup.sh</code>
        </pre>
        <pre data-prefix=">" className="text-warning">
          <code>Setting up projects...</code>
        </pre>
        <pre data-prefix=">" className="text-success">
          <code>✅ Go get em tiger 🐯</code>
        </pre>
      </div>

      <ul className="mt-12 grid grid-cols-1 gap-x-8 gap-y-12 lg:grid-cols-3">
        {FEATURES.map(feature => (
          <li key={feature.title} className="flex flex-col gap-y-8">
            <div className="flex flex-col gap-y-4">
              <div className="flex items-center gap-x-2">
                <span className="h-6 w-6 text-secondary">{feature.icon}</span>

                <HeadingText>{feature.title}</HeadingText>
              </div>

              <ParagraphText>{feature.description}</ParagraphText>
            </div>
          </li>
        ))}
      </ul>

      <SmallText>* Totally Optional.</SmallText>
    </>
  )
}

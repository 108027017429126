import { ComputerDesktopIcon, CreditCardIcon, SparklesIcon } from '@heroicons/react/24/solid'
import { AnchorText, Button, HeadingText, HugeText, ParagraphText, SmallText } from 'components'
import Image from 'next/image'
import React, { ReactNode } from 'react'

import { HighlightText } from '../../highlightText/HighlightText'

const FEATURES: Array<{
  icon: ReactNode
  title: string
  description: ReactNode
}> = [
  {
    icon: <ComputerDesktopIcon />,
    title: 'Next.js Landing Page',
    description: (
      <>
        A stunning landing page built with <AnchorText href="https://nextjs.org/">Next.js</AnchorText>—responsive, fast,
        easily customised, and ready to impress 💻✨
      </>
    ),
  },
  {
    icon: <CreditCardIcon />,
    title: 'Privacy, Terms and Blog',
    description: (
      <>
        Essential pages for your website—privacy policy, terms of service, and a markdown-driven blog. All ready to go
        📜
      </>
    ),
  },
  {
    icon: <SparklesIcon />,
    title: 'Excellent Lighthouse Scores',
    description: (
      <>
        Blazingly fast, optimized with{' '}
        <AnchorText href="https://developer.chrome.com/docs/lighthouse/overview">Lighthouse</AnchorText>. Get that green
        light on performance, accessibility, and SEO out of the box 🌟
      </>
    ),
  },
]

export const WebsiteSection = () => {
  return (
    <>
      <HugeText className="text-center">
        Launch-Ready <HighlightText>Website!</HighlightText>
      </HugeText>

      <ParagraphText className="max-w-2xl text-center">
        A beautiful, responsive landing page out of the box, with essential pages and excellent Lighthouse scores.{' '}
        <b>Ready to launch in minutes</b> 📡
      </ParagraphText>

      <div className="mockup-browser w-full max-w-2xl border border-neutral bg-base-300">
        <div className="mockup-browser-toolbar">
          <div
            className="input"
            style={{
              // override the DaisyUI fixed width
              width: '100%',
            }}
          >
            https://morsemoji.com
          </div>
        </div>
        <div data-theme="dark" className="flex flex-col items-center gap-8 bg-base-200 p-8 sm:flex-row">
          <div className="flex flex-1 flex-col items-center gap-y-4 text-center sm:items-start sm:text-left">
            <div className="flex flex-col gap-y-2">
              <HeadingText>
                Goodbye morse code.
                <br />
                Hello <span className="text-accent">MorseMoji™</span>
              </HeadingText>

              <SmallText>
                Transform old dots and dashes into Emojis! 🐬🔥🏴‍☠️ Make underwater messaging <b>fun and inclusive</b>{' '}
                again!
              </SmallText>
            </div>

            <div>
              <Button className="btn-primary btn-sm">🧜‍♀️ Get Started Now</Button>
            </div>

            <SmallText className="text-xs">
              <b className="text-secondary">Bonus:</b> <i>Morse Karaoke™</i> if you sign up today!
            </SmallText>

            <div className="flex items-center gap-x-2">
              <div className="rating rating-xs">
                <input type="radio" disabled className="mask mask-star bg-yellow-400" />
                <input type="radio" disabled className="mask mask-star bg-yellow-400" />
                <input type="radio" disabled className="mask mask-star bg-yellow-400" />
                <input type="radio" disabled className="mask mask-star bg-yellow-400" />
                <input type="radio" disabled className="mask mask-star bg-yellow-400" defaultChecked />
              </div>

              <SmallText className="text-xs">
                <span className="font-bold">4.9</span> (391 reviews)
              </SmallText>
            </div>
          </div>

          <div className="flex flex-1 justify-center">
            <Image
              src="/morsemoji.webp"
              alt="MorseMoji™"
              width={200}
              height={200}
              className="rounded-full border-2 border-primary"
            />
          </div>
        </div>
      </div>

      <ul className="mt-12 grid grid-cols-1 gap-x-8 gap-y-12 lg:grid-cols-3">
        {FEATURES.map(feature => (
          <li key={feature.title} className="flex flex-col gap-y-8">
            <div className="flex flex-col gap-y-4">
              <div className="flex items-center gap-x-2">
                <span className="h-6 w-6 text-secondary">{feature.icon}</span>

                <HeadingText>{feature.title}</HeadingText>
              </div>

              <ParagraphText>{feature.description}</ParagraphText>
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}

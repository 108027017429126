'use client'

import React from 'react'

import { homeSectionIds } from '@/routes'

export const DemoVideo = () => {
  return (
    <div id={homeSectionIds.demo} className="relative h-0 w-full pb-[62.5%]">
      <iframe
        src="https://www.loom.com/embed/c5dcf44fb14447bcb31d33737296f2e4?sid=5fdb9b8c-73ea-4386-8a34-285b5df526e7"
        title="SUB Demo"
        allowFullScreen
        className="skeleton absolute left-0 top-0 h-full w-full rounded-lg"
      />
    </div>
  )
}

import mixpanel from 'mixpanel-browser'

import { AnalyticsEventData } from '../models'

export const ANALYTICS_ENABLED = process.env.NODE_ENV !== 'development' && process.env.NEXT_PUBLIC_MIXPANEL_TOKEN

if (ANALYTICS_ENABLED) {
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || '', {
    track_pageview: true,
    persistence: 'localStorage',
  })
}

export const identifyUser = (uid: string) => {
  if (ANALYTICS_ENABLED) {
    mixpanel.identify(uid)
  }
}

export const trackAnalyticsEvent = <T extends keyof AnalyticsEventData>(payload: {
  event: T
  data: AnalyticsEventData[T]
}) => {
  const { event, data } = payload

  if (ANALYTICS_ENABLED) {
    mixpanel.track(event, data)
  }
}

export { mixpanel }

'use client'

import { ArrowDownIcon } from '@heroicons/react/24/solid'
import { HeadingText } from 'components'
import { motion } from 'framer-motion'
import React, { useRef, useState } from 'react'

import { trackAnalyticsEvent } from '@/analytics/api/mixpanel'
import { homeSectionIds } from '@/routes'

import { DeploymentSection } from './sections/DeploymentSection'
import { FullStackAppSection } from './sections/FullStackAppSection'
import { GetStartedSection } from './sections/GetStartedSection'
import { ThemingSection } from './sections/ThemingSection'
import { WebsiteSection } from './sections/WebsiteSection'

const FEATURES = [
  {
    id: 'Get Started',
    section: <GetStartedSection />,
  },
  {
    id: 'Full Stack App',
    section: <FullStackAppSection />,
  },
  {
    id: 'Theming',
    section: <ThemingSection />,
  },
  {
    id: 'Website',
    section: <WebsiteSection />,
  },
  {
    id: 'Deployment',
    section: <DeploymentSection />,
  },
]

export const Features = () => {
  const ref = useRef<HTMLDivElement>(null)

  const [activeFeature, setActiveFeature] = useState<(typeof FEATURES)[0] | null>(FEATURES[0])

  return (
    <section id={homeSectionIds.features} className="min-h-screen bg-base-100">
      <div className="relative mx-auto flex w-full max-w-7xl flex-col items-center gap-12 px-8 py-24">
        <HeadingText className="text-secondary">ENGAGE SONAR PINGS!</HeadingText>

        <ArrowDownIcon className="h-8 w-8 animate-bounce text-secondary" />

        <div className="sticky inset-x-0 top-0 z-10 flex w-full items-center justify-center gap-x-8 rounded-lg bg-base-100 p-4">
          {FEATURES.map(feature => (
            <button
              key={feature.id}
              aria-label={`Show feature: ${feature.id}`}
              onClick={() => {
                // scroll to the top
                ref.current?.scrollIntoView({ behavior: 'smooth' })

                setActiveFeature(feature)

                trackAnalyticsEvent({
                  event: 'feature_clicked',
                  data: { feature_id: feature.id },
                })
              }}
              className={`btn btn-circle btn-neutral btn-xs z-10 ${
                activeFeature?.id === feature.id
                  ? 'bg-secondary hover:bg-secondary focus:bg-secondary focus-visible:bg-secondary active:bg-secondary'
                  : 'bg-neutral'
              }`}
            >
              {activeFeature?.id === feature.id && <div className="h-5 w-5 animate-ping rounded-full bg-secondary" />}
            </button>
          ))}
        </div>

        <div ref={ref} className="pt-24">
          {FEATURES.map(feature => (
            <motion.div
              key={feature.id}
              initial="hidden"
              animate={activeFeature?.id === feature.id ? 'visible' : 'hidden'}
              exit="hidden"
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0, transition: { duration: 0.167 } },
              }}
              className={`${activeFeature?.id === feature.id ? 'flex flex-col items-center gap-y-12' : 'hidden'}`}
              aria-hidden={activeFeature?.id !== feature.id}
            >
              {feature.section}
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  )
}

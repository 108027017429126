import { ArrowPathIcon, CommandLineIcon, TagIcon } from '@heroicons/react/24/solid'
import { AnchorText, HeadingText, HugeText, ParagraphText } from 'components'
import React, { ReactNode } from 'react'

import { HighlightText } from '@/components/highlightText/HighlightText'

const FEATURES: Array<{
  icon: ReactNode
  title: string
  description: ReactNode
}> = [
  {
    icon: <CommandLineIcon />,
    title: 'Automated Deployments',
    description: (
      <>
        Code, commit, push and let our{' '}
        <AnchorText href="https://github.com/features/actions">Github Actions</AnchorText> workflows handle the rest.
        Automated staging deployments? Yes, please! 🤖
      </>
    ),
  },
  {
    icon: <TagIcon />,
    title: 'Automated Releases',
    description: (
      <>
        Automated versioning with <AnchorText href="https://semantic-release.gitbook.io/">Semantic Release</AnchorText>,
        changelogs that update themselves, <AnchorText href="https://git-scm.com/">git</AnchorText> tags and{' '}
        <AnchorText href="https://docs.github.com/en/repositories/releasing-projects-on-github/about-releases">
          Github Releases
        </AnchorText>
        . It&apos;s like magic 🪄
      </>
    ),
  },
  {
    icon: <ArrowPathIcon />,
    title: 'Automated Testing',
    description: (
      <>
        CI/CD pipelines that run your tests, check your code quality and ensure that your app is always production-ready
        before deployment. No more broken builds! 🧪
      </>
    ),
  },
]

export const DeploymentSection = () => {
  return (
    <>
      <HugeText className="text-center">
        Ready. Mark. <HighlightText>Deploy!</HighlightText>
      </HugeText>

      <ParagraphText className="max-w-2xl text-center">
        Automated CI/CD pipelines with{' '}
        <AnchorText href="https://github.com/features/actions">Github Actions</AnchorText> and streamlined release
        management to ensure that your app is always production-ready.
        <br />
        With these tools, you can <b>focus on building</b>, not deploying 🚀
      </ParagraphText>

      <div className="mockup-code w-full max-w-lg">
        <pre data-prefix=">">
          <code>git commit</code>
        </pre>
        <pre data-prefix="$" className="text-info">
          <code>git push</code>
        </pre>
        <pre data-prefix=">" className="text-warning">
          <code>Versioning...</code>
        </pre>
        <pre data-prefix=">" className="text-warning">
          <code>Deploying...</code>
        </pre>
        <pre data-prefix=">" className="text-success">
          <code>✅ The world's your oyster 🌍</code>
        </pre>
      </div>

      <ul className="mt-12 grid grid-cols-1 gap-x-8 gap-y-12 lg:grid-cols-3">
        {FEATURES.map(feature => (
          <li key={feature.title} className="flex flex-col gap-y-8">
            <div className="flex flex-col gap-y-4">
              <div className="flex items-center gap-x-2">
                <span className="h-6 w-6 text-secondary">{feature.icon}</span>

                <HeadingText>{feature.title}</HeadingText>
              </div>

              <ParagraphText>{feature.description}</ParagraphText>
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}

import {
  BoltIcon,
  ChartBarIcon,
  ClipboardDocumentCheckIcon,
  CreditCardIcon,
  EnvelopeIcon,
  LockClosedIcon,
} from '@heroicons/react/24/solid'
import { AnchorText, HeadingText, HugeText, ParagraphText, SmallText } from 'components'
import React, { ReactNode } from 'react'

import { HighlightText } from '../../highlightText/HighlightText'

const FEATURES: Array<{
  icon: ReactNode
  title: string
  description: ReactNode
}> = [
  {
    icon: <BoltIcon />,
    title: 'Fast A/F Frontend',
    description: (
      <>
        Accelerate your development with <AnchorText href="https://react.dev/">React</AnchorText>,{' '}
        <AnchorText href="https://vitejs.dev/">Vite</AnchorText>, and{' '}
        <AnchorText href="https://www.typescriptlang.org/">TypeScript</AnchorText>. Your frontend is a
        precision-engineered submarine, cruising at full speed 🚀
      </>
    ),
  },
  {
    icon: <CreditCardIcon />,
    title: 'Optional Payments',
    description: (
      <>
        Equip your app with the power of <AnchorText href="https://stripe.com/">Stripe</AnchorText> payments and
        subscription management, fully integrated and mission-ready 💳
      </>
    ),
  },
  {
    icon: <EnvelopeIcon />,
    title: 'Optional Custom Emails',
    description: (
      <>
        Craft stunning emails with <AnchorText href="https://react.email/">React Email</AnchorText> and{' '}
        <AnchorText href="https://resend.com//">Resend*</AnchorText>, always ready to launch ✉️
      </>
    ),
  },
  {
    icon: <ChartBarIcon />,
    title: 'Optional Analytics & Logging',
    description: (
      <>
        Command your app&apos;s performance with <AnchorText href="https://mixpanel.com/">Mixpanel*</AnchorText> and{' '}
        <AnchorText href="https://sentry.io/">Sentry*</AnchorText>, on standby whenever you need them 📊
      </>
    ),
  },
  {
    icon: <LockClosedIcon />,
    title: 'Authentication Flows',
    description: (
      <>Secure every access point—robust sign-in, sign-up, password recovery, and token management at your service 🔐</>
    ),
  },
  {
    icon: <ClipboardDocumentCheckIcon />,
    title: 'Linting & Testing',
    description: (
      <>
        Fortify your codebase with <AnchorText href="https://prettier.io/">Prettier</AnchorText>,{' '}
        <AnchorText href="https://eslint.org/">ESLint</AnchorText>,{' '}
        <AnchorText href="https://www.typescriptlang.org/">Typescript</AnchorText> and staged linting—keep it clean,
        reliable, and built to endure 🧹
      </>
    ),
  },
]

export const FullStackAppSection = () => {
  return (
    <>
      <HugeText className="text-center">
        Full Stack. Full <HighlightText>Throttle!</HighlightText>
      </HugeText>

      <ParagraphText className="max-w-2xl text-center">
        <AnchorText href="https://firebase.google.com/">Firebase</AnchorText> for the backend.{' '}
        <AnchorText href="https://react.dev/">React</AnchorText>,{' '}
        <AnchorText href="https://vitejs.dev/">Vite</AnchorText>, and{' '}
        <AnchorText href="https://www.typescriptlang.org/">TypeScript</AnchorText> for the frontend.
        <br />
        Your app is <b>fully equipped for any mission</b> 🦸
      </ParagraphText>

      <div className="mockup-code w-full max-w-lg">
        <pre data-prefix=">">
          <code>cd ./packages</code>
        </pre>

        <pre data-prefix="$" className="text-info">
          <code>ls</code>
        </pre>

        <div className="ml-[3.4rem]">
          <div className="flex flex-wrap gap-x-4">
            <code>app</code>

            <code>components</code>

            <code>config</code>

            <code>emails</code>

            <code>functions</code>

            <code>scripts</code>

            <code>styles</code>

            <code>types</code>

            <code>utils</code>

            <code>website</code>
          </div>
        </div>
      </div>

      <ul className="mt-12 grid grid-cols-1 gap-x-8 gap-y-12 lg:grid-cols-3">
        {FEATURES.map(feature => (
          <li key={feature.title} className="flex flex-col gap-y-8">
            <div className="flex flex-col gap-y-4">
              <div className="flex items-center gap-x-2">
                <span className="h-6 w-6 text-secondary">{feature.icon}</span>

                <HeadingText>{feature.title}</HeadingText>
              </div>

              <ParagraphText>{feature.description}</ParagraphText>
            </div>
          </li>
        ))}
      </ul>

      <SmallText>* Or easily BYO service provider.</SmallText>
    </>
  )
}

'use client'

import { motion, useInView } from 'framer-motion'
import React, { ComponentPropsWithoutRef, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

type HighlightProps = ComponentPropsWithoutRef<'span'> & {
  children: string
}

export const HighlightText = ({ className = '', children, ...props }: HighlightProps) => {
  const ref = useRef<HTMLSpanElement>(null)

  const isInView = useInView(ref, { once: true, amount: 'all' })

  const NUMBER_OF_DASHES = children.length

  return (
    <span
      ref={ref}
      className={twMerge(
        'relative whitespace-nowrap text-accent',

        className,
      )}
      {...props}
    >
      <motion.div
        aria-hidden="true"
        className="absolute inset-x-0 -bottom-1 inline-flex w-full justify-between gap-x-2"
        initial="hidden"
        animate={isInView ? 'visible' : false}
        variants={{
          visible: {
            transition: {
              staggerChildren: 0.1, // Adjust this value to control the delay between each torpedo
              ease: 'easeIn', // Slow to fast effect
            },
          },
        }}
      >
        {/* Torpedo Underline */}
        {Array.from({ length: NUMBER_OF_DASHES }).map((_, index) => (
          <motion.span
            key={index}
            className={twMerge(
              'h-2 rounded-sm bg-accent',
              index === NUMBER_OF_DASHES - 1 ? 'animate-pulse bg-secondary' : '',
            )}
            style={{
              // increase the opacity with each index
              opacity: (index + 1) / NUMBER_OF_DASHES,
              // use percentage widths to make them longer with each index
              width: `${(100 * (index + 1)) / NUMBER_OF_DASHES}%`,
            }}
            variants={{
              hidden: { opacity: 0, scale: 0.67 },
              visible: { opacity: 1, scale: 1 },
            }}
          />
        ))}
      </motion.div>

      {children}
    </span>
  )
}

export const BLOG_POST_PARAM = ':slug'

export const homeSectionIds = {
  features: 'features',
  demo: 'demo',
  testimonials: 'testimonials',
  howItWorks: 'how-it-works',
  pricing: 'pricing',
  faqs: 'faqs',
}

export const routes = {
  home: '/',
  blog: '/blog',
  blogPost: `/blog/${BLOG_POST_PARAM}`,
  privacy: '/privacy',
  terms: '/terms',
}
